<template>
  <v-app>
    <VerticalSidebar />
    <v-main>
      <VerticalHeader/>
      <slot />
    </v-main>
  </v-app>
</template>

<style scoped>
@media (min-width: 601px) and (max-width: 780px) {
  .v-container {
    max-width: 780px;
    margin-right: -30px;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .v-container {
    max-width: 1100px;
    margin-right: 0px;
    padding-left: 20px;
  }
}

@media (min-width: 1280px) and (max-width: 1320px) {
  .v-container {
    max-width: 1280px;
    margin-right: -20px;
    padding-left: 50px
  }
}

@media (min-width: 1321px) and (max-width: 1480px) {
  .v-container {
    max-width: 1480px;
    margin-right: -20px;
    padding-left: 50px;
  }
}
</style>
