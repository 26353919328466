<script setup lang="ts">
import { shallowRef } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import sidebarItems from './sidebarItem';

import NavCollapse from '@/components/Sidebar/NavCollapse/NavCollapse.vue';
// // import NavItem from '@/components/Sidebar/NavItem/NavItem.vue';
import NavGroup from '@/components/Sidebar/NavGroup/NavGroup.vue';
const customizer = useCustomizerStore();
const sidebarMenu = shallowRef(sidebarItems);

</script>

<template>
  <v-navigation-drawer left v-model="customizer.Sidebar_drawer" elevation="0" rail-width="60" mobile-breakpoint="lg" app
    class="leftSidebar" :rail="customizer.mini_sidebar" expand-on-hover>
    <!---Logo part -->

    <div class="pa-5">
      <img src="/Sustain-SBI.svg" alt="SBI Logo" class="logo" />
      <img src="/Data Centre.svg" alt="Data Centre" class="logo-Data-Centre" />
    </div>
    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <perfect-scrollbar class="scrollnavbar">
      <v-list aria-busy="true" class="px-2" aria-label="menu list">
        <!---Menu Loop -->
        <template v-for="(item, i) in sidebarMenu" :key="i">
          <!---Item Sub Header -->
          <NavGroup :item="item" v-if="item.header" :key="item.title" />
          <!---Item Divider -->
          <v-divider class="my-3" v-else-if="item.divider" />
          <!---If Has Child -->
          <NavCollapse class="leftPadding" :item="item"  :level="0" v-else-if="item.children" />
          <!---Single Item-->
          <SidebarNavItem :item="item" v-else />
          <!---End Single Item-->
        </template>
      </v-list>

    </perfect-scrollbar>
    
  </v-navigation-drawer>
</template>
<style>
.leftSidebar {
  border: 0px;
  box-shadow: none !important;
  width: auto !important;
}
.logo {
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
}
.logo-Data-Centre{
  width: 120px; 
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
}
</style>